import React, { Component } from 'react';

class FooterPage extends Component {
    state = {}
    render() {
        return (
            <div className="container-custom gradient-blue white-text footer-page">
                ~ Built & Designed by Chris Simbulan ~
            </div>
        );
    }
}

export default FooterPage;